
import GetStarsReposInlast30Days from './components/List/ListView'
function App() {
  return (
    <>
     <GetStarsReposInlast30Days/>
    </>
  );
}

export default App;
